import React, { useMemo } from "react";
import { ScrollView } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Container } from "../../views";
import { AlbumsStackParams } from "./albums-navigator";
import LyricItem from "./components/lyric/lyric";
import { Screen } from "../../views";

import { routes } from "../../config";
import { Lyric } from "../../types/album";
import { useCollectionQuery } from "../../hooks";
import { useQuizzState } from "../../hooks/quizz";

type LyricsScreenNavigationProp = NativeStackScreenProps<
  AlbumsStackParams,
  routes.Lyrics
>;

const LyricsScreen: React.FC<LyricsScreenNavigationProp> = ({
  route,
  navigation,
}) => {
  const { id, title } = route.params.song;
  console.log("id", id)

  const { data = [] } = useCollectionQuery<Lyric>(
    "lyrics",
    {
      where: [["trackId", "==", +id]],
    },
    {
      key: ["lyrics", id],
    }
  );

  const { answeredQuestions } = useQuizzState();

  const lyrics = useMemo(
    () =>
      data
        .map((lyric: Lyric) => ({
          ...lyric,
          reveal:
            lyric.isTitle || lyric.isOutlier || answeredQuestions[lyric.id],
        }))
        .sort((a: Lyric, b: Lyric) => a.position - b.position),
    [data]
  );

  return (
    <Screen style={{ padding: 0 }} title={title}>
      <ScrollView style={{ paddingBottom: 60 }}>
        <Container>
          {lyrics.map(({ id, content, reveal }) => (
            <LyricItem key={id} content={content} reveal={reveal} />
          ))}
        </Container>
      </ScrollView>
    </Screen>
  );
};

export default LyricsScreen;
