import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentDeletion,
  useFirestoreDocumentMutation,
} from "@react-query-firebase/firestore";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getFirestore,
} from "firebase/firestore";

export const generateId = (key: string) => {
  return doc(collection(getFirestore(), key)).id;
};

export const useDocMutation = <T>(key: string, subkey: string) => {
  const query = doc(getFirestore(), key, subkey) as DocumentReference<T>;
  return useFirestoreDocumentMutation(query);
};

export const useCreateMutation = <T>(key: string) => {
  const query = collection(getFirestore(), key) as CollectionReference<T>;
  return useFirestoreCollectionMutation(query);
};

export const useDeleteMutation = (key: string, subkey: string) => {
  const ref = doc(collection(getFirestore(), key), subkey);
  return useFirestoreDocumentDeletion(ref);
};
