import React from "react";
import { View, StyleSheet } from "react-native";
import { Button, Subheading, Title } from "react-native-paper";
import { StackNavigationProp } from "@react-navigation/stack";
import { AuthenticationStackParams } from "./authentication.navigator";
import { routes } from "../../config";
import { Screen, ScreenTypes } from "../../views";
import Cover from "../../views/cover/cover";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingTop: 150,
  },
  description: {
    textAlign: "center",
  },
  btn: {
    marginTop: 15,
  },
});

type AuthenticationScreenProps = StackNavigationProp<
  AuthenticationStackParams,
  routes.AuthHome
>;

const AuthenticationScreen = ({ navigation }: AuthenticationScreenProps) => (
  <Screen title="Profil" type={ScreenTypes.Modal}>
    <Cover
     iconName="account"
      primaryTitle="Connexion"
      secondaryTitle="Inscription"
      onPrimaryPress={() => navigation.navigate("Login")}
      onSecondaryPress={() => navigation.navigate("Register")}
    >
      <Subheading style={styles.description}>
        S'authentifier pour profiter pleinement des avantages de l'application
      </Subheading>
    </Cover>
  </Screen>
);

export default AuthenticationScreen;
