import React from "react";
import PropTypes from "prop-types";
import { StyleSheet } from "react-native";
import { Text, useTheme } from "react-native-paper";

const styles = StyleSheet.create({
  text: {
    padding: 1,
    margin: 3
  }
});

type LyricProps = {
  content: string;
  reveal: boolean;
};

export const Lyric = ({ content, reveal }: LyricProps) => {
  console.log('reveal', reveal);
  const { colors } = useTheme();
  const revealStyle = {
    backgroundColor: colors.primary,
    color: "#FFFFFF00",
    opacity: 0.2
  };
  const textStyle = reveal ?  styles.text : [styles.text, revealStyle]
  return <Text style={textStyle}>{content}</Text>;
};
Lyric.propTypes = {
  content: PropTypes.string.isRequired,
  reveal: PropTypes.bool
};

export default Lyric;
