import React from "react";
import { HelperText } from "react-native-paper";

const getErrorMessage = errorCode => {
  if (errorCode === "register/auth/invalid-email") {
    return "Email incorrect";
  }

  if (errorCode === "register/auth/weak-password") {
    return "Le mot de passe doit contenir au minimum 6 caractères";
  }

  if (errorCode === "register/auth/email-already-in-use") {
    return "Email déja utilisé";
  }

  if (errorCode === "register/auth/name-required") {
    return "Le nom est obligatoire";
  }

  if (
    errorCode === "auth/invalid-email" ||
    errorCode === "auth/wrong-password"
  ) {
    return "Email ou mot de passe incorrect";
  }

  return "seeee";
};

const ErrorMessage = ({ error }) => (
  <HelperText type="error" visible={!!error}>
    {error && getErrorMessage(error)}
  </HelperText>
);

export default ErrorMessage;
