import React from "react";
import { Provider as PaperProvider } from "react-native-paper";
import { QueryClient, QueryClientProvider } from "react-query";
//import AsyncStorage from "@react-native-async-storage/async-storage";
//import { persistQueryClient } from "@tanstack/react-query-persist-client";
//import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
//import { Provider } from "react-redux";
import { theme } from "./config";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import Navigation from "./Navigation";


const firebaseConfig = {
  apiKey: "AIzaSyDOTwK2CXwcagXuRVofE0OT_umBG-Mw7k0",
  authDomain: "ratpisquizz.firebaseapp.com",
  databaseURL: "https://ratpisquizz.firebaseio.com",
  projectId: "ratpisquizz",
  storageBucket: "ratpisquizz.appspot.com",
  messagingSenderId: "437868122197",
  appId: "1:437868122197:web:d927386ccb0a8d8d6244dc",
};

initializeApp(firebaseConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      staleTime: 24 * 60 * 60 * 1000,
    },
  },
});

/*const asyncStoragePersister = createAsyncStoragePersister({
  storage: AsyncStorage,
});*/

/*persistQueryClient({
  queryClient,
  persister: asyncStoragePersister,
});*/

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <PaperProvider theme={{ ...theme }}>
        <Navigation />
      </PaperProvider>
    </QueryClientProvider>
  );
}
