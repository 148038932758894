import { DefaultTheme } from "react-native-paper";

export const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: "#212121",
    accent: "#e0e0e0",
  },
};

export enum routes {
  Loading = "Loading",
  Auth = "Auth",
  AppTabbar = "AppTabbar",
  Quizz = "Quizz",
  AlbumNavigator = "AlbumsNavigator",
  QuizzLoader = "QuizzLoader",
  AccountNavigator = "AccountNavigator",
  Albums = "Albums",
  Songs = "Songs",
  Lyrics = "Lyrics",
  Play = "Play",
  Over = "Over",
  Profil = "Profil",
  Trophy = "Trophy",
  Leaderboard = "Leaderboard",
  AuthHome = "AuthHome",
  Login = "Login",
  Register = "Register",
  Informations = "Informations",
  Shop = "Shop",
  Full = "Full",
}
