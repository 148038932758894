import { buyOfferMutation, useOffers } from "../hooks/shop";
import React from "react";
import { View, StyleSheet } from "react-native";
import { Button, Subheading, Title } from "react-native-paper";
import { routes } from "../config";
import { Screen, ScreenTypes } from "../views";
import Cover from "../views/cover/cover";
import { useQuizzState } from "../hooks/quizz";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingTop: 150,
  },
  description: {
    textAlign: "center",
  },
  btn: {
    marginTop: 15,
  },
});

const FullVersionScreen = ({ navigation }) => {
  const { fullVersion, enableFullVersion } = useQuizzState();
  const { data } = useOffers();
  const { mutateAsync: buy } = buyOfferMutation();
  const pack = data?.current?.availablePackages.find(
    (pack) => pack.product.identifier === "fullVersion"
  );
  const handleOnBuy = async () => {
    
    const done = await buy(pack);
    if (done) {
      enableFullVersion();
      navigation.goBack();
    }

  };
  return (
    <Screen title="Version Complète" type={ScreenTypes.Modal}>
      <Cover
        iconName="play"
        primaryTitle="Acheter"
        secondaryTitle="Non, merci"
        onPrimaryPress={handleOnBuy}
        onSecondaryPress={() => navigation.goBack()}
      >
        <Subheading style={styles.description}>Pas de pub</Subheading>
        <Subheading style={styles.description}>
          Tous les albums jouables
        </Subheading>
        <Title>{pack?.product.price} {pack?.product?.currencyCode}</Title>
      </Cover>
    </Screen>
  );
};

export default FullVersionScreen;
