import React, { useCallback, useEffect, useState, useReducer } from "react";
import { Alert, StyleSheet, View } from "react-native";
import { CommonActions, useNavigation } from "@react-navigation/native";

import { routes } from "../../config";

import { chronoReducer, initialState } from "./store/reducers/chrono.reducer";
import { StartChrono, AddTime } from "./store/actions/chrono.action";

import Header from "./components/header/header";
import Quizz from "./components/quizz/quizz";
import Menu from "./components/menu/menu";
import { useQuestionsQuery } from "../../hooks";
import Loading from "../loading";
import { useQuizzState } from "../../hooks/quizz";
import { Audio } from "expo-av";

let intervalId: any = null;

export default function QuizzScreen({ route }: any) {
  const navigation = useNavigation();
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [goodAnswers, setGoodAnswers] = useState(0);
  const [goodSound, setGoodSound] = useState<any>();
  const [badSound, setBadSound] = useState<any>();

  const { data: questions = [], isLoading, fetchNextPage } = useQuestionsQuery(
    route?.params?.albumId
  );
  const [{ seconds, paused, duration }, dispatchChrono] = useReducer(
    chronoReducer,
    initialState
  );
  const { points, xp, substractPoints, validate } = useQuizzState();

  const currentQuestion = questions[currentIndex];

  const gameOver = () => {
    const resetAction = CommonActions.reset({
      index: 0,
      routes: [{ name: routes.Over, params: { duration, goodAnswers } }],
    });
    navigation.dispatch(resetAction);
  };

  const addTime = ({ value, pts }: { value: number; pts: number }) => {
    dispatchChrono(new AddTime(value));
    substractPoints(pts);
  };
  const handleOnAnswer = useCallback(
    (isGood: boolean) => {
      if (isGood) {
        goodSound?.replayAsync();
      } else {
        badSound?.replayAsync();
      }
      setCurrentIndex((currentIndex) => currentIndex + 1);
      dispatchChrono(new AddTime(isGood ? 5 : -5));
      if (isGood) {
        validate(isGood, currentQuestion);
        setGoodAnswers((answer) => answer + 1);
      }
      if (currentIndex >= questions.length - 2) {
       // Alert.alert("dins");
        fetchNextPage();
      }
    },
    [currentIndex, questions, validate, goodSound]
  );

  const handleOnExist = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const toggleMenu = useCallback(() => {
    setShowMenu((showMenu) => !showMenu);
  }, []);

  useEffect(() => {
    intervalId = setInterval(() => {
      dispatchChrono(new StartChrono());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [dispatchChrono]);

  useEffect(() => {
    if (paused) {
      clearInterval(intervalId);
    }
  }, [paused]);

  useEffect(() => {
    if (!seconds) {
      gameOver();
    }
  }, [gameOver, seconds]);

  const playSound = useCallback(async () => {
    const { sound } = await Audio.Sound.createAsync(
      require("../../assets/good.wav")
    );
    const { sound: badSound } = await Audio.Sound.createAsync(
      require("../../assets/bad.wav")
    );
    setGoodSound(sound as any);
    setBadSound(badSound);
  }, []);

  useEffect(() => {
    playSound();
  }, []);

  useEffect(() => {
    return goodSound
      ? () => {
          console.log("Unloading Sound");
          goodSound?.unloadAsync();
          badSound?.unloadAsync();
        }
      : undefined;
  }, [goodSound]);

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (!currentQuestion) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Header
        seconds={seconds}
        score={xp}
        menuOpened={showMenu}
        toggleMenu={toggleMenu}
      />
      <View style={styles.container}>
        <Quizz question={currentQuestion} onAnswer={handleOnAnswer} />
        <Menu
          show={showMenu}
          pts={points}
          onPress={addTime}
          onExit={handleOnExist}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
