import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import GameOver from "./game-over/game-over";
import { routes } from "../../config";
import QuizzScreen from "./quizz-screen";
import TrophyScreen from "../account/trophy.screen";
import LeaderboardScreen from "../account/leaderboard.screen";

export type QuizzStackParams = {
  [routes.Play]: undefined;
  [routes.Over]: undefined;
};

const Stack = createNativeStackNavigator();

const QuizzNavigator = () => (
  <Stack.Navigator
    screenOptions={{ presentation: "modal", headerShown: false }}
  //  initialRouteName={routes.Over}
  >
    <Stack.Screen name={routes.Play} component={QuizzScreen} />
    <Stack.Screen name={routes.Over} component={GameOver} />
    <Stack.Group screenOptions={{presentation: "card"}}>
      <Stack.Screen name={routes.Trophy} component={TrophyScreen} />
      <Stack.Screen name={routes.Leaderboard} component={LeaderboardScreen} />
    </Stack.Group>
  </Stack.Navigator>
);

export default QuizzNavigator;
