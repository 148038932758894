import React from "react";
import { Text, StatusBar } from "react-native";
import { ProgressBar } from "react-native-paper";
import { Container } from "../views";

const Loading: React.FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <Container style={{ justifyContent: "center" }}>
      <StatusBar barStyle="dark-content" />
      <ProgressBar progress={0.5} indeterminate style={{ width: "100%" }} />
      <Text style={{ textAlign: "center", marginTop: 15 }}>
        Chargement des données du jeu...
      </Text>
    </Container>
  );
};

export default Loading;
