import React, { useCallback } from "react";
import { View, StyleSheet, ViewStyle, StatusBar } from "react-native";
import { Appbar } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import Container from "../container/Container";
import Constants from "expo-constants";

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
});

export enum ScreenTypes {
  Stack = "Stack",
  Modal = "Modal",
  Blank = "Blank",
}

type ScreenProps = {
  title?: string;
  children: React.ReactNode;
  type?: ScreenTypes;
  style?: ViewStyle;
  rightContent?: React.ReactNode;
};

export const Screen = ({
  type = ScreenTypes.Stack,
  title,
  children,
  style,
  rightContent,
}: ScreenProps) => {
  const navigation = useNavigation();
  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <View style={styles.view}>
      <StatusBar barStyle="light-content" />
      {type === ScreenTypes.Blank &&
        !!Constants.platform &&
        Constants.platform.ios && (
          <Appbar>
            <StatusBar barStyle="light-content" />
          </Appbar>
        )}
      {(type === ScreenTypes.Modal || type === ScreenTypes.Stack) && (
        <Appbar.Header
          statusBarHeight={
            Constants.platform && Constants.platform.android ? 0 : undefined
          }
        >
          {type === ScreenTypes.Modal ? (
            <Appbar.Action icon="close" onPress={goBack} />
          ) : (
            <Appbar.BackAction onPress={goBack} />
          )}
          <Appbar.Content title={title} />
          {rightContent}
        </Appbar.Header>
      )}
      <Container style={style}>{children}</Container>
    </View>
  );
};

export default Screen;
