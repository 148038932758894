import React, { useMemo } from "react";
import { FlatList, StyleSheet } from "react-native";
import { Screen } from "../../views";
import Trophy from "./components/trophy";
import { useQuizzState } from "../../hooks/quizz";

const styles = StyleSheet.create({
  container: {
    padding: 0,
  },
  list: {
    padding: 15,
  },
});

const getNumberAnswerByCategory = (
  answereds: { [key: string]: number },
  categories: string[]
): number => {
  return categories
    .map((key) => answereds[key] || 0)
    .reduce((acc, nextVal) => acc + nextVal, 0);
};

const extractKey = (_: any, index: number) => `trophy---${index}`;

const renderItem = ({ item, index }: { item: any; index: number }) => {
  return (
    <Trophy
      key={index}
      title={item.title}
      subtitle={item.subtitle}
      caption={item.caption}
      reached={item.reached}
    />
  );
};

const TrophyScreen = () => {
  const { answeredQuestions, answeredAlbums, started } = useQuizzState();
  const numberOfAnsweredQuestion = useMemo(() => {
    return Object.values(answeredQuestions).length;
  }, [answeredQuestions]);

  const oldSchoolAnswers = useMemo(
    () =>
      getNumberAnswerByCategory(answeredAlbums, ["11269", "11979", "10786"]),
    [answeredAlbums]
  );

  const newSchoolAnswers = useMemo(
    () =>
      getNumberAnswerByCategory(answeredAlbums, [
        "10790",
        "10764",
        "21098",
        "115641",
        "135419",
        "325834",
      ]),
    [answeredAlbums]
  );

  const autopsieAnswers = useMemo(
    () =>
      getNumberAnswerByCategory(answeredAlbums, [
        "11951",
        "10785",
        "13230",
        "12530",
        "334179",
      ]),
    [answeredAlbums]
  );

  const trophies = [
    {
      title: "Exploration",
      subtitle: "Jouer une partie",
      caption: `${started ? 1 : 0}/1`,
      reached: started,
    },
    /* {
      title: "Endurance",
      subtitle: "Jouer une partie plus d'une minute",
      caption: "0",
      reached: false
    },*/
    {
      title: "Apprenti pirate",
      subtitle: "Trouver 100 bonnes réponses",
      caption: `${numberOfAnsweredQuestion}/100`,
      reached: numberOfAnsweredQuestion >= 100,
    },
    {
      title: "Connaisseur",
      subtitle: "Trouver 1000 bonnes réponses",
      caption: `${numberOfAnsweredQuestion}/1000`,
      reached: numberOfAnsweredQuestion >= 1000,
    },

    {
      title: "Old School",
      subtitle:
        "Trouver 1000 bonnes réponses les albums (Temps mort, Pantheon, Ouest Side) ",
      caption: `${oldSchoolAnswers}/1000`,
      reached: oldSchoolAnswers >= 1000,
    },
    {
      title: "Nouvelle génération",
      subtitle:
        "Trouver 1000 bonnes réponses les albums (0.9, Luntatic, Futur, Futur 2.0, D.U.C, Nero Nemesis, Trône et Ultra) ",
      caption: `${newSchoolAnswers}/1000`,
      reached: newSchoolAnswers >= 1000,
    },
    {
      title: "Boss des autopsies",
      subtitle: "Trouver 1000 bonnes réponses parmi les autopsies",
      caption: `${autopsieAnswers}/1000`,
      reached: autopsieAnswers >= 1000,
    },
    {
      title: "Pirate Validé",
      subtitle: "Trouver 10000 bonnes réponses",
      caption: `${numberOfAnsweredQuestion}/10000`,
      reached: numberOfAnsweredQuestion >= 10000,
    },
  ];

  return (
    <Screen title="Trophées" style={styles.container}>
      <FlatList
        style={styles.list}
        data={trophies}
        renderItem={renderItem}
        keyExtractor={extractKey}
        showsVerticalScrollIndicator={false}
      />
    </Screen>
  );
};

export default TrophyScreen;
