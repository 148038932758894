import React, { useEffect, useMemo } from "react";
import { Alert, FlatList, StyleSheet } from "react-native";
import { Screen } from "../../views";
import Trophy from "./components/trophy";
import { useQuizzState } from "../../hooks/quizz";
import Item from "./components/item";
import { buyOfferMutation, useOffers } from "../../hooks/shop";

const styles = StyleSheet.create({
  container: {
    padding: 0,
  },
  list: {
    padding: 15,
  },
});

const extractKey = (_: any, index: number) => `trophy---${index}`;

const ShopScreen = () => {
  const { fullVersion, enableFullVersion } = useQuizzState();
  const { data, error } = useOffers();
  const { mutateAsync: buy } = buyOfferMutation();
  const handleOnBuy = async (pack: any) => {
    const done = await buy(pack);
    if (done) {
      enableFullVersion();
    }
  };

  useEffect(()=>{
    Alert.alert(JSON.stringify(error))
  }, [error])

  const renderItem = ({ item, index }: { item: any; index: number }) => {
    return (
      <Item
        key={index}
        title={item.product.identifier}
        subtitle={item.product.identifier}
        price={item.product.price}
        caption={item.caption}
        reached={fullVersion}
        onBuy={() => handleOnBuy(item)}
      />
    );
  };

  return (
    <Screen title="Boutique" style={styles.container}>
      <FlatList
        style={styles.list}
        data={data?.current?.availablePackages || []}
        renderItem={renderItem}
        keyExtractor={extractKey}
        showsVerticalScrollIndicator={false}
      />
    </Screen>
  );
};

export default ShopScreen;
