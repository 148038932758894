import React from "react";
import { View, StyleSheet, SafeAreaView } from "react-native";
import { Avatar, Button } from "react-native-paper";

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  container: {
    marginTop: 30,
    alignItems: "center",
  },
  btn: {
    width: "100%",
    marginTop: 15,
  },
});
type CoverProps = {
  iconName: string;
  primaryTitle: string;
  secondaryTitle: string;
  onPrimaryPress: () => void;
  onSecondaryPress: () => void;
};
export const Cover: React.FC<CoverProps> = ({
  iconName,
  primaryTitle,
  secondaryTitle,
  children,
  onPrimaryPress,
  onSecondaryPress,
}) => {
  return (
    <SafeAreaView style={styles.view}>
      <View style={styles.container}>
        <Avatar.Icon style={{ marginBottom: 20 }} size={128} icon={iconName} />
        {children}
      </View>
      <View style={{ marginTop: "auto", alignItems: "stretch" }}>
        <Button mode="contained" style={styles.btn} onPress={onPrimaryPress}>
          {primaryTitle}
        </Button>
        <Button style={styles.btn} onPress={onSecondaryPress}>
          {secondaryTitle}
        </Button>
      </View>
    </SafeAreaView>
  );
};

export default Cover;
