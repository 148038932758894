import React from "react";
import { View, StyleSheet } from "react-native";
import { Appbar, Paragraph } from "react-native-paper";
import Constants from "expo-constants";

const styles = StyleSheet.create({
  header: {
    justifyContent: "space-between",
  },
  text: {
    color: "white",
    fontSize: 18,
  },
});

type HeaderProps = {
  score: number;
  seconds: number;
  menuOpened: boolean;
  toggleMenu: () => void;
};

const Header = ({ score, seconds, menuOpened, toggleMenu }: HeaderProps) => {
  return (
    <Appbar.Header
      statusBarHeight={
        Constants.platform && Constants.platform.android ? 0 : undefined
      }
    >
      <Appbar.Action
        icon={menuOpened ? "close" : "menu"}
        onPress={toggleMenu}
      />

      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
        }}
      />
      {/* <Appbar.Content title={`${score} XP`} /> */}
      <Paragraph style={[styles.text]}>{seconds}</Paragraph>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
        }}
      />
      <Paragraph style={[styles.text, { paddingRight: 16 }]}>
        {score + " XP"}
      </Paragraph>
    </Appbar.Header>
  );
};

export default Header;
