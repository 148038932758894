import { useQueryClient } from "react-query";
import { useCallback } from "react";
import { Lyric } from "../types";
import { useAuth } from "./auth";
import { useDocMutation } from "./crud.mutations";
import { useDocQuery } from "./crud.queries";

export interface QuizzState {
  displayName?: string;
  questions: Lyric[];
  points: number;
  xp: number;
  currentIndex: number;
  answeredQuestions: { [key: string]: boolean };
  answeredSongs: { [key: string]: number };
  answeredAlbums: { [key: string]: number };
  started: boolean;
  fullVersion: boolean;
}

const initialState: QuizzState = {
  displayName: "",
  questions: [],
  points: 0,
  currentIndex: 0,
  xp: 0,
  answeredQuestions: {},
  answeredSongs: {},
  answeredAlbums: {},
  started: false,
  fullVersion: false,
};

const getNumbersOfGoodAnswers = (
  answered: { [key: string]: number },
  answeredId: string | number,
  isGood: Boolean
) => {
  if (!isGood) return answered;
  const answerNumber = answered[answeredId] || 0;
  return {
    ...answered,
    [answeredId]: answerNumber + 1,
  };
};

export const useQuizzState = () => {
  const { user } = useAuth();
  const { data, isLoading } = useDocQuery<QuizzState>(
    "users",
    user?.id ?? "hh",
    {
      enabled: !!user?.id,
      key: ["users", user?.id],
    }
  );

  const state = data || initialState;
  const queryClient = useQueryClient();

  const { mutate } = useDocMutation("users", user?.id ?? "ee");

  const save = useCallback(
    (newState: QuizzState) => {
      queryClient.setQueryData(["users", user?.id], newState);
    //  mutate(newState);
    },
    [queryClient, mutate]
  );

  const enableFullVersion = useCallback(() => {
    save({ ...state, fullVersion: true });
  }, [save, state]);

  const validate = useCallback(
    (isGood: boolean, currentQuestion: Lyric) => {
      const xp = isGood ? state.xp + 5 : state.xp;
      const points = isGood ? state.points + 50 : state.points;
      const answeredQuestions = isGood
        ? { ...state.answeredQuestions, [currentQuestion.id]: true }
        : state.answeredQuestions;
      const answeredSongs = getNumbersOfGoodAnswers(
        state.answeredSongs,
        currentQuestion.trackId,
        isGood
      );
      const answeredAlbums = getNumbersOfGoodAnswers(
        state.answeredAlbums,
        currentQuestion.albumId,
        isGood
      );
      const newState = {
        ...state,
        displayName: user?.displayName,
        answeredAlbums,
        answeredSongs,
        answeredQuestions,
        points,
        xp,
        started: true,
      };
      save(newState);
    },
    [state, isLoading, user?.displayName, save]
  );

  const substractPoints = useCallback(
    (points: number) => {
      save({
        ...state,
        points: state.points - points,
      });
    },
    [state, save]
  );

  return {
    ...state,
    validate,
    substractPoints,
    enableFullVersion
  };
};
