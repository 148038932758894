import React, { useCallback } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { Button } from "react-native-paper";

const styles = StyleSheet.create({
  container: {
    height: 20,
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "stretch",
  },
  content: {
    height:"100%",
    backgroundColor: "#fff",
    tintColor: "red",
  },
});

type AnswerProps = {
  style: ViewStyle;
  text: string;
  isGood: Boolean;
  onPress: Function;
};

const Answer: React.FC<AnswerProps> = ({ style, text, isGood, onPress }) => {
  const handleOnPress = useCallback(() => {
    onPress(isGood);
  }, [onPress]);
  return (
    <Button
      mode="outlined"
      style={[styles.container, style]}
      onPress={handleOnPress}
      contentStyle={styles.content}
    >
      {text}
    </Button>
  );
};

export default Answer;
