import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AuthHome from "./authentication.screen";
import Login from "./login.screen";
import Register from "./register.screen";
import Informations from "./informations.screen";
import { routes } from "../../config";
import { useAuth } from "../../hooks/auth";

export type AuthenticationStackParams = {
  [routes.Informations]: undefined;
  [routes.AuthHome]: undefined;
  [routes.Login]: undefined;
  [routes.Register]: undefined;
};

const Stack = createNativeStackNavigator();

const AuthenticationNavigator = () => {
  const { isLoggedIn, isAnonymous } = useAuth();
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={isLoggedIn ? routes.Informations : routes.AuthHome}
    >
      {isLoggedIn && !isAnonymous ? (
        <Stack.Screen name={routes.Informations} component={Informations} />
      ) : (
        <>
          <Stack.Screen name={routes.AuthHome} component={AuthHome} />
          <Stack.Screen name={routes.Login} component={Login} />
          <Stack.Screen name={routes.Register} component={Register} />
        </>
      )}
    </Stack.Navigator>
  );
};

export default AuthenticationNavigator;
