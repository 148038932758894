import {
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  User as FirebaseUser,
} from "firebase/auth";
import {
  useAuthUser,
  useAuthSignInWithEmailAndPassword,
  useAuthCreateUserWithEmailAndPassword,
  useAuthSendPasswordResetEmail,
  useAuthSignInWithPopup,
  useAuthUpdateProfile,
  useAuthUpdatePassword,
  useAuthConfirmPasswordReset,
  useAuthSignOut,
  useAuthSignInAnonymously,
  useAuthLinkWithCredential,
} from "@react-query-firebase/auth";
import { getApp } from "firebase/app";

export type SocialProvider = "facebook" | "google";

export const getProvider = (type: SocialProvider) => {
  if (type === "facebook") {
    return new FacebookAuthProvider();
  }

  if (type === "google") {
    return new GoogleAuthProvider();
  }
};

type Callbacks = {
  onError?: (error: { message?: string; code?: string }) => void;
  onSuccess?: (data: any) => void;
};

export const useLogin = (callbacks?: Callbacks) => {
  return useAuthSignInWithEmailAndPassword(getAuth(), callbacks);
};

export const useForgetPassword = (callbacks?: Callbacks) => {
  return useAuthSendPasswordResetEmail(getAuth(), callbacks);
};

export const useContinueWithSocial = (callbacks?: Callbacks) => {
  return useAuthSignInWithPopup(getAuth(), callbacks);
};

export const useRegister = (callbacks?: Callbacks) => {
  return useAuthCreateUserWithEmailAndPassword(getAuth(), callbacks);
};

export const useUpdateProfile = (callbacks?: Callbacks) => {
  return useAuthUpdateProfile(callbacks);
};

export const useUpdatePassword = (callbacks?: Callbacks) => {
  return useAuthUpdatePassword(callbacks);
};

export const useResetPassword = (callbacks?: Callbacks) => {
  return useAuthConfirmPasswordReset(getAuth(), callbacks);
};

export const useLogout = (callbacks?: Callbacks) => {
  return useAuthSignOut(getAuth(), callbacks);
};

export const useAnonymous = () => {
  return useAuthSignInAnonymously(getAuth(getApp()));
};

export const useLinkWithCredential = ()=>{
  return useAuthLinkWithCredential();
}

const mapUser = (user: FirebaseUser | null) => {
  if (user) {
    return {
      id: user.uid,
      email: user.email ?? "",
      displayName: user.displayName ?? "",
    };
  }
  return null;
};

export const useAuth = () => {
  const { data, isSuccess, isLoading, error } = useAuthUser(
    ["user"],
    getAuth()
  );
  console.log("data", isLoading);
  const user = mapUser(data ?? null);
  const isLoggedIn = !!data;

  const loading = isLoading;

  return {
    user,
    isLoggedIn,
    isAnonymous: data?.isAnonymous,
    loading,
    error,
  };
};
