import React, { useCallback, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { AlbumsStackParams } from "./albums-navigator";
import AlbumList from "./components/album-list/album-list";
import { Screen, ScreenTypes } from "../../views";
import { Album } from "../../types";

import { routes } from "../../config";
import { useCollectionQuery } from "../../hooks";
import { CommonActions } from "@react-navigation/native";
import { LockModal } from "./components/lockModal";
import { useQuizzState } from "../../hooks/quizz";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
  },
});

type AlbumsScreenNavigationProp = NativeStackScreenProps<
  AlbumsStackParams,
  routes.Albums
>;

const AlbumsScreen: React.FC<AlbumsScreenNavigationProp> = ({ navigation }) => {
  const { data = [] } = useCollectionQuery<Album>("albums");
  const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
  const { answeredQuestions, fullVersion } = useQuizzState();
  const nbAnsweredQuestions = Object.keys(answeredQuestions).length;

  const albums = useMemo(
    () =>
      data
        .map((album: Album) => ({
          ...album,
          title: album.name,
          unlocked: nbAnsweredQuestions >= album.requiredAnswers || fullVersion ,
          image: `https://firebasestorage.googleapis.com/v0/b/ratpisquizz.appspot.com/o/${album.id}.jpg?alt=media`,
        }))
        .sort((a: Album, b: Album) => b.year - a.year),
    [data, fullVersion]
  );

  const goToDetail = useCallback(
    (album: Album) => {
      navigation.navigate(routes.Songs, {
        album: { ...album, unlocked: album.unlocked || fullVersion },
      });
    },
    [navigation]
  );
  const handleOnPlay = (album: Album, unlocked: boolean) => {
    if (!unlocked) {
      setSelectedAlbum(album);
      return;
    }
    navigation.dispatch(
      CommonActions.navigate(routes.Quizz, {
        screen: routes.Play,
        params: { albumId: album.id },
      })
    );
  };

  return (
    <Screen type={ScreenTypes.Blank} style={styles.container}>
      <LockModal
        album={selectedAlbum}
        visible={!!selectedAlbum}
        onClose={() => setSelectedAlbum(null)}
      />
      <AlbumList
        albums={albums}
        onPress={goToDetail}
        onPlay={handleOnPlay}
      />
    </Screen>
  );
};

export default AlbumsScreen;
