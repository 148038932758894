import React, { useCallback, useEffect } from "react";
import { StyleSheet, Share, View, Alert } from "react-native";
import { CommonActions } from "@react-navigation/native";
import {
  Appbar,
  Avatar,
  Button,
  Paragraph,
  Subheading,
  Title,
} from "react-native-paper";
import { AdMobRewarded, setTestDeviceIDAsync } from "expo-ads-admob";
import { routes } from "../../../config";
import { Screen, ScreenTypes } from "../../../views";
import Cover from "../../../views/cover/cover";
import { useQuizzState } from "../../../hooks/quizz";

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  container: {
    marginTop: 30,
    alignItems: "center",
  },
  btn: {
    width: "100%",
    marginTop: 15,
  },
});

const GameOver = ({ navigation, route }: any) => {
  const { duration, goodAnswers } = route?.params ?? {
    duration: 0,
    goodAnswers: 0,
  };
  const { xp, fullVersion } = useQuizzState();
  const handleOnPress = useCallback(() => {
    const resetAction = CommonActions.reset({
      index: 0,
      routes: [{ name: routes.Play }],
    });
    navigation.dispatch(resetAction);
  }, [navigation]);

  const onShare = async () => {
    try {
      await Share.share({
        message: `J'ai trouvé ${goodAnswers} réponses en ${duration} secondes. #ratpisQuizz`,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    if (fullVersion) return;
    const getPub = async () => {
      try {
        await setTestDeviceIDAsync("EMULATOR");
        AdMobRewarded.setAdUnitID("ca-app-pub-7216608116348070~3132243103"); // Test ID, Replace with your-admob-unit-id
        await AdMobRewarded.requestAdAsync();
        await AdMobRewarded.showAdAsync();
      } catch (error) {
        Alert.alert(JSON.stringify(error));
      }
      //await setTestDeviceIDAsync("EMULATOR");
    };

    getPub();
  }, [fullVersion]);

  return (
    <Screen
      title="Game over"
      type={ScreenTypes.Modal}
      rightContent={
        <>
          <Appbar.Action
            icon="trophy"
            color="white"
            onPress={() => navigation.navigate(routes.Trophy)}
          />
          <Appbar.Action
            icon="format-list-numbered"
            color="white"
            onPress={() => navigation.navigate(routes.Leaderboard)}
          />
        </>
      }
    >
      <Cover
        iconName="alarm-off"
        primaryTitle="Rejouer"
        secondaryTitle="Partager"
        onPrimaryPress={handleOnPress}
        onSecondaryPress={onShare}
      >
        <Title>Ouloulou ! </Title>
        <Subheading>Tu pers, I win</Subheading>
        <Paragraph style={{ textAlign: "center" }}>
          Vous avez trouvé{" "}
          <Paragraph style={{ fontWeight: "bold" }}>
            {route?.params?.goodAnswers} réponses
          </Paragraph>{" "}
          en{" "}
          <Paragraph style={{ fontWeight: "bold" }}>
            {route?.params?.duration} secondes
          </Paragraph>
        </Paragraph>
        <Title>{xp} XP</Title>
      </Cover>
    </Screen>
  );
};

export default GameOver;
