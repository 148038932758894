import React from "react";
import { StyleSheet, SafeAreaView } from "react-native";
import { Container } from "../../../../views";
import Question from "../question/question";
import Answers from "../answers/answers";

const styles = StyleSheet.create({
  container: {
    zIndex: 0,
    flexDirection: "column"
  },
  content: {
    flex: 1
  }
});

const Quizz = ({ question, onAnswer }) => (
  <Container style={styles.container}>
    <SafeAreaView style={styles.content}>
      <Question text={question.content} />
      <Answers answers={question.answers} onPress={onAnswer} />
    </SafeAreaView>
  </Container>
);

export default Quizz;
