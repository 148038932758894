import {
  collection,
  doc,
  documentId,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  Query,
  where,
} from "firebase/firestore";
import { CollectionParams, QueryParams, WhereCondition } from "../types/api";
import {  useMemo } from "react";
import { useQuery } from "react-query";
import { Lyric } from "types";
import { useFirestoreInfiniteQuery } from "@react-query-firebase/firestore";

const getList = <T>(key: string, params?: CollectionParams) => {
  let myCollection = query(collection(getFirestore(), key));
  if (params?.where?.length) {
    params.where.forEach(([field, condition, value]) => {
      myCollection = query(myCollection, where(field, condition, value));
    });
  }
  if (params?.limit) {
    myCollection = query(myCollection, limit(params.limit));
  }
  return myCollection as Query<T>;
};

export const useCollectionQuery = <T>(
  key: string,
  params?: CollectionParams,
  queryParams?: QueryParams
) => {
  return useQuery(
    queryParams?.key ?? [key],
    async () => {
      const queryData = getList<T>(key, params);
      const data = await getDocs(queryData);
      console.log("data", data.docs);
      return data.docs.map((doc) => ({
        ...doc.data(),
        id: (doc.data() as any).id ?? doc.id,
      }));
    },
    queryParams
  );
};

export const useDocQuery = <T>(
  key: string,
  subkey: string,
  params?: QueryParams
) => {
  return useQuery<T | null>(
    [key, subkey],
    async () => {
      const query = doc(getFirestore(), key, subkey) as DocumentReference<T>;
      const response = await getDoc(query);
      if (!response.data()) return null;
      const values = {
        ...response.data(),
        id: response.id,
      };

      return (values as unknown) as T;
    },
    params
  );
};

export const useQuestionsQuery = (albumId = "") => {
 
  const quizzQuery = useMemo(() => {
    const newCityRef = doc(collection(getFirestore(), "lyrics"));
    const documentRef = newCityRef.id;
    const commons: WhereCondition[] = [
      [documentId(), ">=", documentRef],
      ["isOutlier", "==", false],
      ["isTitle", "==", false],
    ];
    if (albumId) {
      commons.push(["albumId", "==", albumId]);
    }
    return getList<Lyric>("lyrics", { where: commons, limit: 5 });
  }, [albumId]);

  const questions = useFirestoreInfiniteQuery("posts", quizzQuery, (snapshot) => {
    const lastDocument = snapshot.docs[snapshot.docs.length - 1];
    return query<Lyric>(quizzQuery, where(documentId(), ">", lastDocument.id));
  });

  return useMemo(() => {
    console.log("ouloulou")
    const data = questions.data?.pages
      .map((snapshot) =>
        snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: (doc.data() as any).id ?? doc.id,
        }))
      )
      .reduce((acc, next) => [...acc, ...next], []);
    return {
      ...questions,
      data,
    };
  }, [questions.data]);
};
