import React, { useState, useCallback } from "react";
import { StyleSheet } from "react-native";
import { Appbar, Button, Subheading, TextInput } from "react-native-paper";
import { routes } from "../config";
import { Screen, ScreenTypes } from "../views";
import ErrorMessage from "./account/error-message";
import {
  useLinkWithCredential,
  useRegister,
  useUpdateProfile,
} from "../hooks/auth";
import { EmailAuthProvider, getAuth } from "firebase/auth";

const styles = StyleSheet.create({
  btn: {
    marginTop: 15,
  },
});

const ContactScreen = ({ navigation }) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const {
    mutateAsync: linkWithCredential,
    isLoading: registerLoading,
  } = useLinkWithCredential();
  const {
    mutateAsync: updateProfile,
    isLoading: updateProfileLoading,
  } = useUpdateProfile();
  const loading = registerLoading || updateProfileLoading;

  const register = useCallback(async () => {
    if (!displayName) return setError("register/auth/name-required");
    try {
      const user = getAuth().currentUser;
      if (!user) {
        return;
      }

      await linkWithCredential({
        user,
        credential: EmailAuthProvider.credential(email, password),
      });
      await updateProfile({
        user,
        displayName,
      });
      navigation.navigate(routes.AuthHome);
    } catch (error) {
      setError("register/" + error.code);
    }
  }, [displayName, email, password]);

  return (
    <Screen title="Contact">
      <Subheading style={{marginBottom: 16}}>
        Pour toutes vos questions. Merci de nous envoyer votre message en
        utilisant le formulaire ci-dessous{" "}
      </Subheading>
      <TextInput
        label="Nom"
        value={displayName}
        onChangeText={(text) => setDisplayName(text)}
      />
      <TextInput
        label="Email"
        value={email}
        onChangeText={(text) => setEmail(text)}
      />
      <TextInput
        label="votre message"
        multiline
        numberOfLines={5}
        value={password}
        onChangeText={(text) => setPassword(text)}
        secureTextEntry
      />
      <ErrorMessage error={error} />
      <Button
        mode="contained"
        style={styles.btn}
        loading={loading}
        disabled={loading}
        onPress={register}
      >
        Envoyer
      </Button>
    </Screen>
  );
};

export default ContactScreen;
