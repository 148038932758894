import React, { useState, useCallback } from "react";
import { StyleSheet } from "react-native";

import { Button, TextInput } from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AuthenticationStackParams } from "./authentication.navigator";
import { routes } from "../../config";
import { Screen, ScreenTypes } from "../../views";
import ErrorMessage from "./error-message";
import { useLogin } from "../../hooks/auth";

const styles = StyleSheet.create({
  btn: {
    marginTop: 15,
  },
});

type LoginScreenProps = NativeStackScreenProps<
  AuthenticationStackParams,
  routes.Login
>;

const LoginScreen: React.FC<LoginScreenProps> = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { mutateAsync, isLoading } = useLogin();

  const login = useCallback(async () => {
    try {
      await mutateAsync({ email, password });
      navigation.goBack();
    } catch (error) {
      setError(error.code);
    }
  }, [email, password]);

  return (
    <Screen title="Connexion">
      <TextInput
        label="Email"
        value={email}
        onChangeText={(text) => setEmail(text)}
      />
      <TextInput
        label="Mot de passe"
        value={password}
        onChangeText={(text) => setPassword(text)}
        secureTextEntry
      />
      <ErrorMessage error={error} />

      <Button
        mode="contained"
        style={styles.btn}
        onPress={login}
        loading={isLoading}
        disabled={isLoading}
      >
        connexion
      </Button>
    </Screen>
  );
};

export default LoginScreen;
