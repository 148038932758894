import React from "react";
import { StyleSheet, View } from "react-native";
import suffle from "shuffle-array";
import Answer from "./answer-item";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    paddingVertical: 15,
  },
  answer: {
    marginVertical: 15,
    flex: 1,
    minHeight:50,
    alignItems: "stretch", 
  },
});

type Answer = {
  text: string;
  isGood: Boolean;
};

type AnswersProps = {
  answers: Answer[];
  onPress: Function;
};

const Answers = ({ answers, onPress }: AnswersProps) => (
  <View style={styles.container}>
    {suffle(answers).map(({ text, isGood }) => (
      <Answer
        key={text}
        style={styles.answer}
        text={text}
        isGood={isGood}
        onPress={onPress}
      />
    ))}
  </View>
);

export default React.memo(Answers);
