export const START_CHRONO = "[QUIZZ] START_CHRONO";
export const ADD_TIME = "[QUIZZ] ADD_TIME";
export const PAUSE_CHRONO = "[QUIZZ] PAUSE CHRONO";

export class StartChrono {
  public type = START_CHRONO;
}

export class AddTime {
  public type = ADD_TIME;
  constructor(public payload: number) {}
}

export class PauseChrono {
  public type = PAUSE_CHRONO;
}

export type chronoActionTypes = AddTime | StartChrono | PauseChrono;
