import React from "react";
import { StyleSheet, FlatList } from "react-native";
import AlbumItem from "../album/album";
import { Album } from "../../../../types/album";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 15,
  },
  item: {
    marginVertical: 15,
  },
});

type AlbumListProps = {
  albums: Album[];
  onPress: Function;
  fullVersion: boolean;
  onPlay: (album: Album, unlocked: boolean) => void;
};
const AlbumList = ({
  albums,
  onPress,
  onPlay,
}: AlbumListProps) => (
  <FlatList
    style={styles.container}
    data={albums}
    keyExtractor={(item: any) => "" + item.id}
    renderItem={({ item }) => (
      <AlbumItem
        style={styles.item}
        album={item}
        onPress={onPress}
        unlocked={ item.unlocked}
        onPlay={onPlay}
      />
    )}
  />
);

export default AlbumList;
