import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AccountScren from "./account.screen";
import TrophyScreen from "./trophy.screen";
import LeaderboardScreen from "./leaderboard.screen";
import ShopScreen from "./shop.screen";
import { routes } from "../../config";

const Stack = createNativeStackNavigator();

const AccountNavigator = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen name={routes.Profil} component={AccountScren} />
    <Stack.Screen name={routes.Trophy} component={TrophyScreen} />
    <Stack.Screen name={routes.Leaderboard} component={LeaderboardScreen} />
    <Stack.Screen name={routes.Shop} component={ShopScreen} />
  </Stack.Navigator>
);

export default AccountNavigator;
