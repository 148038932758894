import React, { useLayoutEffect } from "react";
import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import Authentication from "./screens/account/authentication.navigator";
import AppTab from "./AppTabbar";
import Quizz from "./screens/quizz/quizzNavigator";

import { routes } from "./config";
import { useAnonymous, useAuth } from "./hooks/auth";
import Loading from "./screens/loading";
import FullVersionScreen from "./screens/fullVersion.screen";
import ContactScreen from "./screens/contact.screen";

const AppStack = createNativeStackNavigator();

const linking: LinkingOptions<any> ={
  prefixes: ["https://ratpisquizz.com"], 
  config:{
    screens: {
        [routes.AppTabbar] : "route", 
        contact: 'contact'
    }

  }
}

const AppNavigator = () => {
  const { isLoggedIn, loading } = useAuth();
  const { mutateAsync: login, isLoading: LoginLoading } = useAnonymous();

  useLayoutEffect(() => {
    if (loading || isLoggedIn) return;
    (async () => {
      try {
        await login({ email: "", password: "" });
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, [loading, isLoggedIn]);

  if (loading || LoginLoading) {
    return <Loading isLoading={true} />;
  }

  return (
    <NavigationContainer linking={linking}>
      <AppStack.Navigator
        //initialRouteName={routes.Quizz}
        screenOptions={{ presentation: "fullScreenModal", headerShown: false }}
      >
        <AppStack.Screen name={routes.AppTabbar} component={AppTab} />
        <AppStack.Screen name={routes.Quizz} component={Quizz} />
        <AppStack.Screen name={routes.Auth} component={Authentication} />
        <AppStack.Screen name={routes.Full} component={FullVersionScreen} />
        <AppStack.Screen name={'contact'} component={ContactScreen} />
      </AppStack.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigator;
