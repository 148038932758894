import React, { useCallback } from "react";
import { View, StyleSheet } from "react-native";
import {
  Avatar,
  Card,
  Text,
  Title,
  Button,
  Caption,
  Headline,
} from "react-native-paper";

const styles = StyleSheet.create({
  content: {
    flexDirection: "row",
    marginBottom: 8,
  },
  description: {
    flex: 1,
    marginLeft: 15,
    marginTop: -8,
  },
  caption: {
    textAlign: "right",
  },
  actions: { flexDirection: "row", justifyContent: "space-between" },
});

type TrophyProps = {
  title: string;
  subtitle: string;
  caption: string;
  price: number;
  reached: boolean;
  onBuy: () => void;
};

const Item = ({ title, subtitle, price, reached, onBuy }: TrophyProps) => {
  const containerStyle = { marginBottom: 30 };

  return (
    <Card style={containerStyle}>
      <Card.Content>
        <View style={styles.content}>
          <Avatar.Icon size={40} icon={reached ? "check-bold" : "stop"} />
          <View style={styles.description}>
            <Title>{title}</Title>
            <Text>{subtitle}</Text>
          </View>
        </View>
          {reached && <Caption style={styles.caption}>Acheté</Caption>}
        {!reached && (
          <View style={styles.actions}>
            <Headline style={styles.caption}>{price}€</Headline>
            <Button mode="contained" onPress={onBuy}>
              payer
            </Button>
          </View>
        )}
      </Card.Content>
    </Card>
  );
};

export default Item;
