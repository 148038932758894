import React, { useCallback } from "react";
import { View, StyleSheet, SafeAreaView } from "react-native";
//import firebase from "firebase";
import {
  Avatar,
  Button,
  TextInput,
  IconButton,
  HelperText,
} from "react-native-paper";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AuthenticationStackParams } from "./authentication.navigator";
import { routes } from "../../config";
import { Screen, ScreenTypes } from "../../views";
import { useAuth, useLogout } from "../../hooks/auth";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  avatar: {
    position: "relative",
    alignSelf: "center",
    marginBottom: 30,
  },
  avatarIcon: {
    position: "absolute",
    bottom: 15,
    left: 70,
  },
  btn: {
    marginTop: "auto",
  },
});

type InformationsScreenProps = NativeStackScreenProps<
  AuthenticationStackParams,
  routes.Informations
>;

const InformationsScreen: React.FC<InformationsScreenProps> = ({
  navigation,
}) => {
  const { user } = useAuth();
  console.log('infos', user)
  const { mutateAsync: signOut } = useLogout();
  const logout = useCallback(async () => {
    await signOut();
    navigation.goBack();
  }, [navigation]);

  return (
    <Screen title="Profil" type={ScreenTypes.Modal}>
      <SafeAreaView style={styles.container}>
        <View style={styles.avatar}>
          <Avatar.Text
            size={120}
            label={(user && user.displayName.charAt(0).toUpperCase()) || "R"}
          />
        </View>
        <TextInput
          label="Nom"
          value={(user && user.displayName) || ""}
          disabled
        />
        <TextInput label="Email" value={(user && user.email) || ""} disabled />
        <Button style={styles.btn} color="red" onPress={logout}>
          Déconnexion
        </Button>
      </SafeAreaView>
    </Screen>
  );
};

export default InformationsScreen;
