import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, View, ViewStyle } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15
  }
});

type containerProps = {
  children?: React.ReactNode;
  style?: ViewStyle;
};

export const Container = ({ children, style }: containerProps) => (
  <View style={[styles.container, style]}>{children}</View>
);

Container.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object
};

export default Container;
