import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AlbumsScreen from "./albums.screen";
import SongsScreen from "./songs.screen";
import LyricsScreen from "./lyrics.screen";

import { Album, Song } from "../../types";

import { routes } from "../../config";

export type AlbumsStackParams = {
  [routes.Albums]: undefined;
  [routes.Songs]: { album: Album };
  [routes.Lyrics]: { song: Song };
};

const Stack = createNativeStackNavigator<AlbumsStackParams>();

const AlbumsNavigator = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen name={routes.Albums} component={AlbumsScreen} />
    <Stack.Screen name={routes.Songs} component={SongsScreen} />
    <Stack.Screen name={routes.Lyrics} component={LyricsScreen} />
  </Stack.Navigator>
);

export default AlbumsNavigator;
