import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import AlbumNavigator from "./screens/albums/albums-navigator";
import AccountNavigator from "./screens/account/account.navigator";
import QuizzLoader from "./screens/quizz/quizz-loader";
import { MaterialIcons } from "@expo/vector-icons";
import { theme, routes } from "./config";
import { useNavigation } from "@react-navigation/native";

const TabStack = createBottomTabNavigator();

const AppTabNavigator = () => {
  const navigation = useNavigation();
  return (
    <TabStack.Navigator
      screenOptions={{
        tabBarActiveTintColor: theme.colors.primary,
        tabBarInactiveTintColor: "#9e9e9e",
      }}
    >
      <TabStack.Screen
        name={routes.AlbumNavigator}
        component={AlbumNavigator}
        options={{
          tabBarLabel: "Albums",
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <MaterialIcons name="library-music" size={25} color={color} />
          ),
        }}
      />
      <TabStack.Screen
        name={routes.QuizzLoader}
        component={QuizzLoader}
        options={{
          tabBarLabel: "Quizz",
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <MaterialIcons name="play-arrow" size={25} color={color} />
          ),
        }}
        listeners={{
          tabPress: (e) => {
            e.preventDefault();
            navigation.navigate(routes.Quizz);
          },
        }}
      />
       <TabStack.Screen
        name={routes.AccountNavigator}
        component={AccountNavigator}
        options={{
          tabBarLabel: "Compte",
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <MaterialIcons name="account-box" size={25} color={color} />
          ),
        }}
      /> 
    </TabStack.Navigator>
  );
};

export default AppTabNavigator;
