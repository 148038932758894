import React from "react";
import { StyleSheet, SafeAreaView, View } from "react-native";
import { Container } from "../../../../views";
import { Button, Title, Subheading } from "react-native-paper";
import MenuItem from "./menu-item";

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    backgroundColor: "white",
    zIndex: 2,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between", 
    alignItems: "center"
  },
  item: {
    marginVertical: 15,
  },
});

const items = [
  {
    title: "Ajouter 30 secondes",
    pts: 500,
    value: 30,
    text: "30",
    isText: true,
  },
  {
    title: "Ajouter une minute",
    pts: 1000,
    value: 60,
    text: "60",
    isText: true,
  },
  { title: "Arrêter le chrono", pts: 10000, value: 0, icon: "stop" },
];

const Menu = ({ show, onPress, onExit, pts }) => {
  if (!show) return null;
  return (
    <SafeAreaView style={styles.container}>
      <Container>
        <View style={styles.header}>
          <Title>Boutique</Title>
          <Subheading>{pts} pts</Subheading>
        </View>
        {items.map((item) => (
          <MenuItem
            {...item}
            key={item.value}
            disabled={pts < item.pts}
            style={styles.item}
            onPress={onPress}
          />
        ))}

        <Button style={{ marginTop: "auto" }} color="red" onPress={onExit}>
          Quitter la partie
        </Button>
      </Container>
    </SafeAreaView>
  );
};

export default React.memo(Menu);
