import { useNavigation } from "@react-navigation/native";
import { useQuizzState } from "../../../hooks/quizz";
import React, { useState } from "react";
import { Button, Dialog, Paragraph, Portal } from "react-native-paper";
import { Album } from "types";
import { routes } from "../../../config";

type LockModal = {
  album: Album | null;
  visible: boolean;
  onClose: () => void;
};

export const LockModal: React.FC<LockModal> = ({
  album,
  visible,
  onClose,
}) => {
  const { navigate } = useNavigation();
  const { answeredQuestions } = useQuizzState();
  const nbAnsweredQuestions = Object.keys(answeredQuestions).length;
  return (
    <Portal>
      <Dialog visible={visible} onDismiss={onClose}>
        <Dialog.Title>Débloquer {album?.name}</Dialog.Title>
        <Dialog.Content>
          <Paragraph>
            Il faut trouver {nbAnsweredQuestions ?? 0}/{album?.requiredAnswers} ou passer à
            la version Premium
          </Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={onClose}>Fermer</Button>
          <Button
            onPress={() => {
              onClose();
              navigate(routes.Full);
            }}
          >
            {" "}
            Premium
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
