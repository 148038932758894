import React from "react";
import { ScrollView } from "react-native";
import { Button } from "react-native-paper";

import { List, Divider, Title, useTheme } from "react-native-paper";
import { Screen } from "../../views";
import { routes } from "../../config";
import { useCollectionQuery } from "../../hooks";
import { User } from "types";
import { useAuth } from "../../hooks/auth";
import { QuizzState } from "hooks/quizz";

const LeaderboardScreen = ({ navigation: { navigate } }) => {
  const { isLoggedIn, isAnonymous, user: currentUser } = useAuth();
  const theme = useTheme();
  const { data: users = [] } = useCollectionQuery<QuizzState>("users");
  return (
    <Screen title="Top 100">
      <ScrollView>
        <List.Section>
          {users
            .sort((a: QuizzState, b: QuizzState) => b?.xp - a?.xp)
            .map((user: User, index: number) => (
              <React.Fragment key={user.id}>
                <Divider />
                <List.Item
                  title={user.displayName || "Ratpi"}
                  description={user.xp + " XP"}
                  left={() => <Title>{index + 1} - </Title>}
                  style={{backgroundColor: currentUser?.id === user.id ? theme.colors.accent : undefined }}
                />
              </React.Fragment>
            ))}
          <Divider />
        </List.Section>
      </ScrollView>
     
    </Screen>
  );
};

export default LeaderboardScreen;
