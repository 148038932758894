import {
  START_CHRONO,
  chronoActionTypes,
  ADD_TIME,
} from "../actions/chrono.action";

export interface ChronoState {
  seconds: number;
  paused: boolean;
  duration: number;
}

export const initialState: ChronoState = {
  seconds: 30,
  paused: false,
  duration: 0,
};

export function chronoReducer(state, action): ChronoState {
  switch (action.type) {
    case START_CHRONO: {
      const seconds = state.seconds - 1;

      return {
        ...state,
        seconds: seconds > 0 ? seconds : 0,
        duration: state.duration + 1,
      };
    }
    case ADD_TIME: {
      const seconds = state.seconds + action.payload;
      const paused = !action.payload;
      return {
        ...state,
        seconds: seconds < 0 ? 0 : seconds,
        paused,
      };
    }
  }
  return state;
}
