import React, { useCallback, useMemo, useState } from "react";
import { ScrollView, Image } from "react-native";
import { Card, Appbar } from "react-native-paper";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { CommonActions, RouteProp } from "@react-navigation/native";

import Songs from "./components/songs/songs";

import { AlbumsStackParams } from "./albums-navigator";
import { Screen } from "../../views";
import { Lyric, Song } from "../../types";

import { routes } from "../../config";
import { useCollectionQuery } from "../../hooks";
import { LockModal } from "./components/lockModal";

type SongsScreenNavigationProp = NativeStackScreenProps<
  AlbumsStackParams,
  routes.Songs
>;

const SongsScreen: React.FC<SongsScreenNavigationProp> = ({
  route,
  navigation,
}) => {
  const [visible, setVisible] = useState(false);
  const { id, name, image, unlocked } = route.params.album;
  const { data = [], error } = useCollectionQuery<Song>(
    "tracks",
    {
      where: [["albumId", "==", +id]],
    },
    {
      key: ["tracks", id],
    }
  );
  console.log("albumId", unlocked);
  console.log("tracks", error);
  const tracks: Song[] = useMemo(
    () => data.sort((a: Song, b: Song) => a.position - b.position),
    [data]
  );

  const goToLyrics = useCallback(
    (song) => {
      navigation.navigate(routes.Lyrics, { song });
    },
    [navigation]
  );

  const playQuizz = useCallback(() => {
    if(!unlocked){
      return setVisible(true);

    }
  
    navigation.dispatch(
      CommonActions.navigate(routes.Quizz, {
        screen: routes.Play,
        params: { albumId: id },
      })
    );
  }, [id,unlocked, navigation]);

  return (
    <Screen
      title={name}
      style={{ padding: 0 }}
      rightContent={<Appbar.Action icon="play" onPress={playQuizz} />}
    >
      <LockModal
        album={route.params.album}
        visible={visible}
        onClose={() => setVisible(false)}
      />
      <ScrollView>
        <Card>
          <Image
            source={{ uri: image }}
            resizeMode="contain"
            style={{ width: "100%", aspectRatio: 1 }}
          />
        </Card>
        <Songs items={tracks} onPress={goToLyrics} />
      </ScrollView>
    </Screen>
  );
};

export default SongsScreen;
