import React from "react";
import PropTypes from "prop-types";
import { StyleSheet } from "react-native";
import { Card, Paragraph } from "react-native-paper";

const styles = StyleSheet.create({
  container: {
    height: 200,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 0
  },
  content: {
    textAlign: "center", 
    fontSize: 20
  }
});

type QuestionProps = {
  text: string;
};

const Question = ({ text }: QuestionProps) => (
  <Card style={{flexShrink: 1}}>
    <Card.Content style={styles.container}>
      <Paragraph style={styles.content}>{text}</Paragraph>
    </Card.Content>
  </Card>
);

Question.propTypes = {
  text: PropTypes.string.isRequired
};

export default Question;
