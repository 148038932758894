import { Platform } from "react-native";
import Purchases from "react-native-purchases";
import { useMutation, useQuery } from "react-query";

const APIKeys = {
  apple: "appl_OJheKMhqvODIFDEhrTWNWjFGlMy",
  google: "your_revenuecat_google_api_key",
};

const getOffers = async () => {
  Purchases.setDebugLogsEnabled(true);
  if (Platform.OS == "android") {
    await Purchases.configure({ apiKey: APIKeys.google });
  } else {
    await Purchases.configure({ apiKey: APIKeys.apple });
  }

  return Purchases.getOfferings();
};

export const useOffers = () => {
  return useQuery(["offers"], getOffers);
};

export const buyOfferMutation = () => {
  return useMutation(async (pack: any) => {
    const { customerInfo, productIdentifier } = await Purchases.purchasePackage(
      pack
    );
    return (
      typeof customerInfo.entitlements.active.my_entitlement_identifier !==
      "undefined"
    );
  });
};
