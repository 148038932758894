import React from "react";
import {
  Appbar,
  Avatar,
  Headline,
  Subheading,
  Card,
  List,
  Divider,
} from "react-native-paper";
import { View, StyleSheet } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Screen, ScreenTypes } from "../../views";
import { routes } from "../../config";
import { useAuth } from "../../hooks/auth";
import {  useQuizzState } from "../../hooks/quizz";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 15,
  },
  profil: {
    padding: 15,
    paddingBottom: 10,
    flexDirection: "row",
  },
  profilText: {
    marginHorizontal: 15,
  },
});

const Account = ({ navigation: { navigate } }: StackNavigationProp) => {
  const { user } = useAuth();
  const { xp, points } = useQuizzState();
  return (
    <Screen type={ScreenTypes.Blank}>
      <Card>
        <View style={styles.profil}>
          <Avatar.Text
            size={60}
            label={(user && user.displayName.charAt(0).toUpperCase()) || "R"}
          />
          <View style={styles.profilText}>
            <Headline>{(user && user.displayName) || "Ratpis"}</Headline>
            <Subheading>{xp} xp - {points} pts </Subheading>
          </View>
        </View>

        <List.Section>
          <Divider />
          <List.Item
            title="Profil"
            left={() => <List.Icon icon="account-box" />}
            right={() => <List.Icon icon="chevron-right" />}
            onPress={() => navigate(routes.Auth)}
          />
          <Divider />
          <List.Item
            title="Trophées"
            left={() => <List.Icon icon="trophy" />}
            right={() => <List.Icon icon="chevron-right" />}
            onPress={() => navigate("Trophy")}
          />
          <Divider />
          <List.Item
            title="Top 100"
            left={() => <List.Icon color="#000" icon="format-list-numbered" />}
            right={() => <List.Icon icon="chevron-right" />}
            onPress={() => navigate("Leaderboard")}
          />
          <List.Item
            title="Boutique"
            left={() => <List.Icon color="#000" icon="store" />}
            right={() => <List.Icon icon="chevron-right" />}
            onPress={() => navigate("Shop")}
          />
        </List.Section>
      </Card>
    </Screen>
  );
};
export default Account;
