import React from "react";
import { View } from "react-native";
import { List, Divider } from "react-native-paper";

import { Song } from "../../../../types/album";

type SongsProps = {
  items: Song[];
  onPress: Function;
};

const Songs = ({ items, onPress }: SongsProps) => (
  <List.Section>
    {items.map((item) => (
      <View key={item.id}>
        <List.Item
          key={item.id}
          title={item.title}
          onPress={() => {
            onPress(item);
          }}
        />
        <Divider />
      </View>
    ))}
  </List.Section>
);
export default Songs;
