import React, { useState, useCallback } from "react";
import { StyleSheet, Text } from "react-native";
import { Avatar, Card } from "react-native-paper";

const styles = StyleSheet.create({
  error: {
    color: "red"
  }
});

const Subtitle = ({ pts, showError }) => (
  <>
    <Text>{`${pts} pts`}</Text>
    {showError && <Text style={styles.error}> - pts insuffisants</Text>}
  </>
);

const MenuItem = ({
  style,
  isText = false,
  text = "",
  icon = null,
  title,
  value,
  pts,
  disabled,
  onPress
}) => {
  const [showError, setShowError] = useState(false);
  const handleOnPress = useCallback(() => {
    if (disabled) {
      setShowError(true);
    } else {
      onPress({ value, pts });
    }
  }, [onPress, disabled]);

  const renderIcon = useCallback(
    props => {
      if (isText) {
        return <Avatar.Text {...props} label={text} />;
      }
      return <Avatar.Icon {...props} icon={icon} />;
    },
    [isText]
  );

  return (
    <Card style={style} onPress={handleOnPress}>
      <Card.Title
        title={title}
        subtitle={<Subtitle pts={pts} showError={showError} />}
        left={renderIcon}
      />
    </Card>
  );
};

export default MenuItem;
