import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { StyleSheet, View, Image, ViewStyle } from "react-native";
import { Button, Card } from "react-native-paper";
import { Album } from "../../../../types/album";;

const styles = StyleSheet.create({
  overlayContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  title: {
    position: "absolute",
    color: "#fff",
    bottom: 5,
    left: 5,
  },
  button: {
    position: "absolute",
    bottom: 10,
    right: 10,
    color: "#fff",
    borderColor: "#fff",
  },
});

type AlbumProps = {
  album: Album;
  onPress: Function;
  style?: ViewStyle;
  unlocked: boolean;
  onPlay: (album: Album, unlocked: boolean) => void;
};

export const AlbumItem = ({
  album,
  style,
  onPress,
  unlocked,
  onPlay,
}: AlbumProps) => {
  const { image, name } = album;

  const handleOnPress = useCallback(() => {
    onPress(album);
  }, [onPress]);

  return (
    <Card style={style} onPress={handleOnPress} elevation={10} mode="elevated">
      <Image
        source={{ uri: image }}
        resizeMode="contain"
        style={{ width: "100%", aspectRatio: 1 }}
      />
      <View
        style={[
          styles.overlayContainer,
          { backgroundColor: unlocked ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0.9)" },
        ]}
      >
        <Button
          style={styles.button}
          color="#fff"
          icon={unlocked ? "play" : "lock"}
          mode="outlined"
          onPress={() => onPlay(album, unlocked)}
        >
          Jouer
        </Button>
      </View>
    </Card>
  );
};

AlbumItem.propTypes = {
  album: PropTypes.shape({
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default AlbumItem;
