import React, { useCallback } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, Card, Text, Caption, Title } from "react-native-paper";

const styles = StyleSheet.create({
  content: {
    flexDirection: "row"
  },
  description: {
    flex: 1,
    marginLeft: 15,
    marginTop: -8
  },
  caption: {
    textAlign: "right"
  }
});

type TrophyProps = {
  title: string;
  subtitle: string;
  caption: string;
  reached: boolean;
};

const Trophy = ({ title, subtitle, caption, reached }: TrophyProps) => {
  const containerStyle = { opacity: reached ? 1 : 0.5, marginBottom: 30 };

  return (
    <Card style={containerStyle}>
      <Card.Content style={styles.content}>
        <Avatar.Icon size={40} icon={reached ? "check-bold" : "stop"} />
        <View style={styles.description}>
          <Title>{title}</Title>
          <Text>{subtitle}</Text>
          <Caption style={styles.caption}>{caption}</Caption>
        </View>
      </Card.Content>
    </Card>
  );
};

export default Trophy;
